<template>
  <div>
    <!-- Cabeçalho -->
    <b-card class="card-congratulations">
      <h1 class="text-white">Contrato: {{ dados.codigo }}</h1>
      <b-card-text>
        <b-row>
          <b-col md="4" class="mb-1"> <strong>Tomador: </strong>{{ dadosPrimeiraCamada.nomeCompleto }} </b-col>
          <b-col md="4" class="mb-1"> <strong>Matrícula: </strong>{{ dadosPrimeiraCamada.matricula }} </b-col>
          <b-col md="4" class="mb-1"> <strong>CPF: </strong>{{ dadosPrimeiraCamada.cpf }} </b-col>
          <b-col md="4" class="mb-1">
            <strong>Data de nascimento: </strong>{{ formatarData(dadosPrimeiraCamada.dataNascimento) }}
          </b-col>
          <b-col md="4" class="mb-1"> <strong>Categoria: </strong>{{ dadosPrimeiraCamada.categoria }} </b-col>
          <b-col md="4" class="mb-1">
            <strong>Status Financeiro: </strong>
            <b-badge pill :variant="status[0][dados.statusFinanceiro]">
              {{ dados.statusFinanceiro }}
            </b-badge>
          </b-col>
          <b-col v-if="dados.statusFinanceiro == 'Consolidado'" md="4" class="mb-1">
            <strong>Data Inicio</strong>
            {{ formatarData(dados.proposta.primeiraParcela) }}
          </b-col>
          <b-col v-if="dados.statusFinanceiro == 'Consolidado'" md="4" class="mb-1">
            <strong>Data Fim: </strong>
            {{ formatarData(dataFim) }}
          </b-col>
          <b-col v-if="dados.statusFinanceiro == 'Consolidado'" md="4" class="mb-1">
            <strong>Valor: </strong>
            {{ String(dados.valorContrato).includes('R$') ? dados.valorContrato : formatarValor(dados.valorContrato) }}
          </b-col>
          <b-col v-if="dados.statusFinanceiro == 'Consolidado'" md="4" class="mb-1">
            <strong>Quantidade de Parcelas: </strong>{{ dados.numeroParcelas }}
          </b-col>
          <b-col v-if="dados.statusFinanceiro == 'Consolidado'" md="4" class="mb-1">
            <strong>Data do Contrato: </strong>{{ formatarData(dados.createdAt) }}
          </b-col>
          <b-col v-if="vendedor" md="4" class="mb-1"> <strong>Vendedor: </strong> {{ vendedor }} </b-col>
        </b-row>

        <validation-observer ref="formContratoPendente">
          <b-form>
            <b-row>
              <!-- Data Requerimento -->
              <b-col md="3">
                <b-form-group class="label-branco" label="Data Requerimento" label-for="createdAt">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="createdAt" v-model="dados.createdAt" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Valor -->
              <b-col md="3">
                <b-form-group class="label-branco" label="Valor total do empréstimo" label-for="valor">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="valor" v-model="dados.valorComIOF" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Valor Total com Juros -->
              <b-col md="3">
                <b-form-group class="label-branco" label="Valor Total do Empréstimo com Juros %" label-for="valorTotalComJuros">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="valorTotalComJuros" v-model="dados.valorTotalComJuros" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Taxa -->
              <b-col md="3">
                <b-form-group class="label-branco" label="Taxa" label-for="taxa">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="taxa" v-model="dados.taxas" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Valor 1ª Parcela -->
              <b-col md="3">
                <b-form-group class="label-branco" label="Valor da 1ª Parcela" label-for="valor1Parcela">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="valor1Parcela" v-model="dados.valorParcela" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Quantidade de Parcelas -->
              <b-col md="3">
                <b-form-group class="label-branco" label="Nº de Parcelas" label-for="qtdParcelas">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="qtdParcelas" v-model="dados.numeroParcelas" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Data Vencimento -->
              <b-col md="3">
                <b-form-group class="label-branco" label="Data Vencimento" label-for="dataVencimento">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="dataVencimento" v-model="dados.vencimento" :disabled="true" />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group class="label-branco" label="Índice Inflacionário" label-for="taxaIndice">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="taxaIndice" v-model="dadosPrimeiraCamada.taxaIndiceInflacionario" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Saldo Devedor Inicial -->
              <b-col md="3">
                <b-form-group class="label-branco" label="Saldo Devedor Inicial" label-for="saldoDevedorInicial">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="saldoDevedorInicial" v-model="dados.valorContrato" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Valor da Dívida -->
              <b-col md="3" v-if="parametro.compraDeDivida === true">
                <b-form-group class="label-branco" label="Valor da Dívida" label-for="valorDaDivida">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="valorDaDivida" v-model="parametro.valorDaDivida" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Saldo Remanescente -->
              <b-col md="3" v-if="parametro.compraDeDivida === true">
                <b-form-group class="label-branco" label="Saldo Remanescente" label-for="saldo Remanescente">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="saldoRemanescente" v-model="parametro.valorRemanescente" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Banco-->
              <b-col md="3" v-if="parametro.compraDeDivida === true">
                <b-form-group class="label-branco" label="Banco" label-for="bancoDivida">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="bancoDivida" v-model="parametro.nomeBanco" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Parcelas quitadas-->
              <b-col md="3" v-if="parametro.compraDeDivida === true">
                <b-form-group class="label-branco" label="Quantidade de parcelas quitadas" label-for="parcelasQuitadas">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="parcelasQuitadas" v-model="parametro.proposta.parcelasQuitadas" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Aprovado Por -->
              <b-col md="3" v-if="infoAvancada == true">
                <b-form-group class="label-branco" label="Aprovado Por" label-for="aprovadoPor">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="aprovadoPor" v-model="aprovadoPor" :disabled="true" />
                </b-form-group>
              </b-col>

              <!-- Navegador Utilizado -->
              <b-col md="3" v-if="infoAvancada == true">
                <b-form-group class="label-branco" label="Navegador Utilizado" label-for="navegadorUtilizado">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input
                    v-else
                    id="navegadorUtilizado"
                    v-model="dadosPrimeiraCamada.navegadorUtilizado"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

              <!-- Data e Hora Aprovação -->
              <b-col md="3" v-if="infoAvancada == true">
                <b-form-group class="label-branco" label="Data e Hora Aprovação" label-for="dataAprovado">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input v-else id="dataAprovado" v-model="dadosPrimeiraCamada.dataAprovado" :disabled="true" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="infoAvancada == true && dados.statusFinanceiro == 'Consolidado'">
              <b-col md="3" class="mb-1">
                <b-form-group class="label-branco" label="Data da Primeira Parcela" label-for="dataDaPrimeiraParcela">
                  <b-skeleton v-if="isBusy" type="input" />
                  <b-form-input
                    v-else
                    id="dataDaPrimeiraParcelaInput"
                    v-model="dadosPrimeiraCamada.dataPrimeiraParcela"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col lg="3" class="mb-1">
                    <b-skeleton v-if="isBusy" type="button" class="d-inline-block mr-1" />
                    <b-button
                      v-if="!isBusy"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="secondary"
                      class="w-100 mr-1"
                      @click.prevent="gerarContrato"
                    >
                      Baixar Minuta
                    </b-button>
                  </b-col>
                  <b-col lg="3" class="mb-1" v-if="acesso.FinanceiroAnexarArquivos">
                    <b-skeleton v-if="isBusy" type="button" class="d-inline-block mr-1 w-100 p-1" />
                    <b-button
                      v-else
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="w-100 mr-1"
                      variant="info"
                      @click.prevent="enviarDocumento"
                    >
                      Anexar Documento(s)
                    </b-button>
                  </b-col>
                  <b-col lg="3" v-if="dados.statusFinanceiro == 'Pendente' && !isBusy" class="mb-1">
                    <b-skeleton
                      v-if="dados.statusFinanceiro == 'Pendente' && isBusy"
                      type="button"
                      class="d-inline-block mr-1"
                    />
                    <b-button
                      class="w-100 mr-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      @click.prevent="authentication('save')"
                    >
                      <feather-icon v-if="dados.origem == 'Aplicativo'" icon="CheckSquareIcon" size="16" />
                      {{ dados.origem == 'Aplicativo' ? 'Aprovar' : 'Concluir Contrato' }}
                    </b-button>
                  </b-col>
                  <b-col
                    lg="3"
                    v-if="dados.statusFinanceiro == 'Pendente' && dados.origem == 'Aplicativo' && !isBusy"
                    class="mb-1"
                  >
                    <b-skeleton
                      type="button"
                      class="d-inline-block mr-1"
                      v-if="dados.statusFinanceiro == 'Pendente' && isBusy"
                    />
                    <b-button
                      class="w-100 mr-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      @click="decisaoContrato(dados.id, dados.propostaId)"
                    >
                      <feather-icon icon="XSquareIcon" size="16" />
                      <span class="align-middle ml-50">Negar</span>
                    </b-button>
                  </b-col>
                  <b-col lg="3" v-if="!isBusy" class="mb-1">
                    <b-skeleton v-if="isBusy" type="button" class="d-inline-block mr-1" />
                    <b-button
                      class="w-100 mr-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="info"
                      @click.prevent="abreModalAlterarMatriculaVinculada"
                    >
                      Alterar Matrícula Vinculada
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-card>

    <b-tabs>
      <b-tab active @click="tabClicked('contratoContratoParcelas')">
        <template #title>
          <feather-icon icon="DivideSquareIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Parcelas</span>
        </template>
        <contratoContratoParcelas :parametro="parametro" @protocoloOkEvent="okMethod" />
      </b-tab>

      <b-tab v-if="acesso.FinanceiroConsultarDocumentos" @click="tabClicked('contratoContratoPendenteProtocoloVirtual')">
        <template #title>
          <feather-icon icon="FilePlusIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Arquivos</span>
        </template>
        <contratoContratoPendenteProtocoloVirtual
          :parametro="parametro"
          :tomador="dadosPrimeiraCamada"
          @protocoloOkEvent="okMethod"
          @atualizarManusearDocumentosEvent="manipularDocumentos($event)"
        />
      </b-tab>

      <b-tab
        v-if="userData.role == 'master' || acesso.FinanceiroInformacoesAvancadas"
        @click="tabClicked('ContratoContratoInformacoesAvancadas')"
      >
        <template #title>
          <feather-icon icon="ListIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Informações Avançadas</span>
        </template>
        <ContratoContratoInformacoesAvancadas :parametro="parametro" @protocoloOkEvent="okMethod" />
      </b-tab>
    </b-tabs>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>

    <b-modal
      id="modal-altera-matricula-vinculada"
      no-close-on-backdrop
      no-close-on-esc
      ref="modal-altera-matricula-vinculada"
      centered
      title="Alteração da Matrícula Vinculada"
      hide-footer
      size="xl"
    >
      <AlterarMatriculaVinculada
        @fecharModalAlterarMatriculaVinculada="fecharModalAlterarMatriculaVinculada"
        :parametro="parametroAlteracaoMatricula"
      />
    </b-modal>

    <b-modal
      id="modal-enviar-documento"
      ref="modal-enviar-documento"
      size="lg"
      centered
      title="Anexar Documento(s)"
      hide-footer
    >
      <upload-Arquivo @uploadEvent="uploadMethod" :parametro="parametro" />
    </b-modal>

    <b-modal
      id="modal-parametro-documento"
      ref="modal-parametro-documento"
      centered
      title="Modelo(s) de minuta cadastrado(s)"
      no-close-on-backdrop
      hide-footer
    >
      <b-row>
        <b-col md="12">
          <b-table
            hover
            responsive
            class="position-relative"
            :items="itemsModeloDocumento"
            :fields="fieldsModelo"
            thead-class="d-none"
            @row-clicked="myRowClickHandler"
          >
            <template #cell(nome)="row">
              <b class="text-primary">{{ row.item.nome.toLowerCase() }}</b>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-geraDoc" ref="modal-geraDoc" centered no-close-on-backdrop hide-footer hide-header>
      <contratoGeraContrato @contratoEvent="contratoMethod" :parametro="geraContrato" />
    </b-modal>

    <b-modal
      id="modal-motivo-recusa"
      ref="modal-motivo-recusa"
      centered
      size="sm"
      title="Motivo para negar o contrato"
      hide-footer
    >
      <b-row>
        <b-col md="12">
          <validation-provider #default="{ errors }" :rules="'required'" name="Motivo">
            <b-form-input
              style="text-transform: uppercase"
              id="Motivo"
              type="text"
              placeholder="Motivo"
              v-model="denyContract.motivoRecusaContrato"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            class="mt-2 mr-1"
            v-if="denyContract.motivoRecusaContrato"
            @click.prevent="authentication('deny')"
            title="Este botão enviará por qual motivo que o contrato foi negado"
          >
            Enviar motivo
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import vSelect from 'vue-select'
  import { VueAutosuggest } from 'vue-autosuggest'
  import {
    formatarValor,
    formatarData,
    formatarDataHora,
    formatarValorPorcentagem,
    formatarValorReais,
    formatarParaNumber,
  } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import contratoContratoPendenteProtocoloVirtual from './contratoContratoPendenteProtocoloVirtual'
  import contratoContratoParcelas from './contratoContratoParcelas'
  import contratoGeraContrato from './contratoGeraContrato.vue'
  import UploadArquivo from '@/views/components/uploadArquivo'
  import moment from 'moment'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import ContratoContratoInformacoesAvancadas from './ContratoContratoInformacoesAvancadas.vue'
  import AlterarMatriculaVinculada from './components/AlterarMatriculaVinculada.vue'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      vSelect,
      VueAutosuggest,
      AutenticaSenha,
      contratoContratoPendenteProtocoloVirtual,
      ContratoContratoInformacoesAvancadas,
      contratoContratoParcelas,
      contratoGeraContrato,
      UploadArquivo,
      formatarData,
      formatarData,
      formatarValorReais,
      AlterarMatriculaVinculada,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        dados: {},
        dadosPrimeiraCamada: {
          nomeCompleto: '',
          matricula: '',
          cpf: '',
          dataNascimento: '',
          categoria: '',
          observacao: '',
          statusFinanceiro: '',
          taxaIndiceInflacionario: '',
          navegadorUtilizado: '',
          dataAprovado: '',
          dataPrimeiraParcela: '',
        },
        isBusy: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
        number: {},
        suggestions: [],
        timeout: null,
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        required,

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        infoAvancada: false,
        sortDirection: 'asc',
        pageOptions: [25, 50, 100],
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 25, currentPage: 1 },
        striped: true,
        requerimentoOk: false,
        //Campos tabela
        fields: [
          { key: 'parcela', label: 'Nº da Parcela', sortable: true },
          { key: 'valor', label: 'Valor', sortable: true },
          { key: 'taxa', label: 'Taxa' },
          { key: 'encargo', label: 'Encargo' },
          { key: 'vencimento', label: 'Vencimento' },
          { key: 'pagamento', label: 'Pagamento' },
        ],
        tabela: [],
        itemsModeloDocumento: [],
        dataFim: '',
        geraContrato: {
          s3url: null,
          contratoId: null,
          userId: null,
          nomeDocumento: null,
          idDocumento: null,
          modeContrato: null,
        },
        fieldsModelo: [{ key: 'nome', label: 'Documento Modelo' }],
        acesso: {
          FinanceiroAnexarArquivos: null,
          FinanceiroConsultarDocumentos: null,
          FinanceiroInformacoesAvancadas: null,
        },
        status: [
          {
            Consolidado: 'success',
            Pendente: 'warning',
            Quitado: 'info',
          },
        ],
        aprovadoPor: '',
        documentosOk: false,
        statusRetorno: ['atencao', 'alerta', 'erro'],
        denyContract: {
          id: null,
          propostaId: null,
          motivoRecusaContrato: null,
        },
        vendedor: '',
        parametroAlteracaoMatricula: {
          seguradoId: null,
        },
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.FinanceiroAnexarArquivos = true
        this.acesso.FinanceiroConsultarDocumentos = true
        this.acesso.FinanceiroInformacoesAvancadas = true
      } else {
        this.acesso.FinanceiroAnexarArquivos = this.userData.GrupoAcesso.FinanceiroAnexarArquivos
        this.acesso.FinanceiroConsultarDocumentos = this.userData.GrupoAcesso.FinanceiroConsultarDocumentos
        this.acesso.FinanceiroInformacoesAvancadas = this.userData.GrupoAcesso.FinanceiroInformacoesAvancadas
      }

      this.parametro.valorDaDivida = formatarValorReais(this.parametro.proposta.valorDaDivida)
      this.parametro.valorRemanescente = formatarValorReais(this.parametro.proposta.valorRemanescente)

      this.tabela = this.parametro.parcelas
      this.dataFim = this.parametro.parcela.sort((a, b) => a.parcela - b.parcela).pop().vencimento

      if (this.parametro.proposta.usuarioAprovadoId) {
        this.buscaUsuarioCadastrou()
      }
      this.formataDadosDoContrato()

      if (this.parametro.usuarioId) {
        this.carregarVendedor()
      }
    },
    methods: {
      async carregarVendedor() {
        await useJwt
          .get(`cadastro/user/${this.parametro.usuarioId}`)
          .then((response) => {
            this.vendedor = response.data.name
          })
          .catch((error) => {
            console.error(error)
          })
      },
      tabClicked(tabName) {
        if (tabName === 'ContratoContratoInformacoesAvancadas') {
          this.infoAvancada = true
        } else {
          this.infoAvancada = false
        }
      },
      carregarGrid() {
        useJwt
          .pesquisar(`financeiro/GetbuscaParcelas/${this.parametro.id}`)
          .then((response) => {
            this.tabela = response.data
            this.totalRows = response.data.length
          })
          .catch((error) => {
            console.error(error)
          })

        useJwt
          .get(`financeiro/carregarProcessoVirtual/${this.parametro.id}`)
          .then((response) => {
            this.tabela = response.data
            this.totalRows = response.data.length
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async gerarContrato() {
        useJwt
          .pesquisar('cadastro/documentoModelo/Getpesquisar', {
            ...this.pesquisar,
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.itemsModeloDocumento = response.data
            this.$refs['modal-parametro-documento'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      myRowClickHandler(row, index) {
        try {
          this.downloadModelo(row.id, index)
        } catch (error) {
          this.$message.error('Erro ao gerar a minuta')
          console.error(error)
          this.$refs['modal-geraDoc'].hide()
          this.$refs['modal-parametro-documento'].hide()
        }
      },
      async downloadModelo(item) {
        const data = (await useJwt.get('financeiro/' + this.parametro.id)).data
        useJwt
          .downloadDocumento(`cadastro/documentoModelo/download`, item)
          .then((response) => {
            this.geraContrato.s3Key = response.data.s3Key
            this.geraContrato.s3url = response.data.s3url
            this.geraContrato.contratoId = this.parametro.id
            this.geraContrato.userId = this.userData.id
            this.geraContrato.nomeDocumento = response.data.nome
            this.geraContrato.idDocumento = response.data.id
            this.geraContrato.modeContrato = 'download'

            this.$refs['modal-geraDoc'].show()
          })
          .catch((error) => {
            console.error(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'O arquivo desejado não foi encontrado!',
                  icon: 'error',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
          })
      },
      contratoMethod(data, msg) {
        if (data == 'error') {
          this.$refs['modal-geraDoc'].hide()
          this.$refs['modal-parametro-documento'].hide()
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: msg,
                icon: 'error',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else if (data) {
          this.$refs['modal-geraDoc'].hide()
          this.$refs['modal-parametro-documento'].hide()
        }
      },
      enviarDocumento() {
        this.$refs['modal-enviar-documento'].show()
      },
      uploadMethod(data) {
        if (data) {
          this.$root.$emit('atualizarProtocolo', true)
          this.$refs['modal-enviar-documento'].hide()
        }
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'error',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      isDateValid(data) {
        var Title
        const day = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD')
        const validDate =
          data == ''
            ? ''
            : /^(((0[1-9]|[12][0-9]|3[01])([-.\/])(0[13578]|10|12)([-.\/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([-.\/])(0[469]|11)([-.\/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([-.\/])(02)([-.\/])(\d{4}))|((29)(\.|-|\/)(02)([-.\/])([02468][048]00))|((29)([-.\/])(02)([-.\/])([13579][26]00))|((29)([-.\/])(02)([-.\/])([0-9][0-9][0][48]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][2468][048]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][13579][26])))$/.test(
                data,
              )
            ? true
            : false
        if (validDate) {
          const insertData = moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD')
          if (day > insertData) {
            ;(Title = 'Erro: A data inserida é menor que a data atual. '), this.msgError(Title)
          } else {
            return true
          }
        } else {
          ;(Title = 'Erro: A data inserida não é válida. '), this.msgError(Title)
        }
      },
      okMethod(data) {
        if (data) this.requerimentoOk = true
        else this.requerimentoOk = false
      },
      authentication(mode) {
        if (mode == 'save') {
          this.$refs.formContratoPendente.validate().then((success) => {
            if (success) {
              //FIX verifica essa funcionalidade && this.isDateValid(this.parametro.vencimento)) {
              this.parametro.mode = mode
              this.$refs['modal-auth-senha'].show()
            }
          })
        } else {
          this.parametro.mode = mode
          this.$refs['modal-auth-senha'].show()
        }
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.parametro.mode == 'save') {
            this.salvar()
          } else if (this.parametro.mode == 'deny') {
            this.$refs['modal-motivo-recusa'].hide()
            this.cancelarContrato()
          } else if (this.parametro.mode == 'deny') {
            this.$refs['modal-motivo-recusa'].hide()
            this.cancelarContrato()
          }
        }
      },
      salvar() {
        const component = this

        this.$refs.formContratoPendente.validate().then(async (success) => {
          if (success) {
            if (!this.parametro.id) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erro: O contrato a ser configurado não está sendo identificado, reinicie o sistema. ',
                    icon: 'error',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            } else {
              const dados = {
                ...this.parametro,
                user: {
                  ...this.userData,
                },
              }
              useJwt
                .update(`financeiro/putContratoPendente`, this.parametro.id, dados)
                .then((response) => {
                  if (this.statusRetorno.includes(response.data.status)) {
                    this.warning(response.data.mensagem)
                    return
                  }
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Contrato concluído com sucesso!',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })

                  useJwt
                    .put('proposta/PutContratoGerado', { id: this.parametro.propostaId })
                    .then((response) => {})
                    .catch((error) => {
                      console.error(error)
                    })

                  this.$emit('reloadComponent', component.parametro.contratoItemId)
                })
                .catch((error) => {
                  console.error(error)
                })
            }
          }
        })
      },
      decisaoContrato(id, propostaId) {
        this.denyContract = {
          id: id,
          propostaId: propostaId,
        }
        this.$refs['modal-motivo-recusa'].show()
      },
      cancelarContrato() {
        const component = this
        useJwt
          .post(`financeiro/putCancelarContratoPendente`, this.denyContract)
          .then((response) => {
            this.$emit('reloadComponent', component.parametro.contratoItemId)
          })
          .catch((error) => {
            console.error(error)
          })
      },
      buscaUsuarioCadastrou() {
        this.isBusy = true
        useJwt
          .get('cadastro/user/' + this.parametro.proposta.usuarioAprovadoId)
          .then((response) => {
            this.aprovadoPor = response.data.name
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      warning(messge) {
        this.$swal({
          title: 'Atenção!',
          text: messge,
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      },
      manipularDocumentos(dados) {
        const filesIds = dados.files.flatMap((file) => {
          if (file.documento && file.documento.documentoCheckList) {
            return file.documento.documentoCheckList
              .filter((checklist) => checklist.documentoId !== null)
              .map((checklist) => checklist.documentoId)
          }
          return
        })

        const docIds = dados.checklist.flatMap((check) => {
          if (check.documento && check.documento.id !== null) {
            return check.documento.id
          }
          return
        })
        this.documentosOk = this.verificarItensNaLista(docIds, filesIds)
      },
      verificarItensNaLista(lista1, lista2) {
        return lista1.every((item) => lista2.includes(item))
      },
      formataDadosDoContrato() {
        this.dados = {
          ...this.parametro,
          createdAt: formatarData(this.parametro.createdAt),
          vencimento: formatarData(this.parametro.vencimento),
          valorTotalComJuros: formatarValor(this.parametro.valorTotalComJuros),
          valorContrato: formatarValor(this.parametro.valorContrato),
          taxas: formatarValorPorcentagem(this.parametro.taxas),
          valorParcela: formatarValor(this.parametro.valorParcela),
          valorComIOF: formatarValor(parseFloat(this.parametro.valorBaseCalculo) + parseFloat(this.parametro.valorIOF)),
          proposta: {
            ...this.parametro.proposta,
            taxaIndiceInflacionario: formatarValorPorcentagem(
              Number(this.parametro.proposta.segurado.tomador.instituto.taxaIndiceInflacionario),
            ),
            dataAprovado: formatarDataHora(this.parametro.proposta.dataAprovado),
          },
        }
        this.dadosPrimeiraCamada = {
          nomeCompleto: this.parametro.proposta.segurado.tomador.nomeCompleto,
          matricula: this.parametro.proposta.segurado.matricula,
          cpf: this.parametro.proposta.segurado.tomador.cpf,
          dataNascimento: formatarData(this.parametro.proposta.segurado.tomador.dataNascimento),
          categoria: this.parametro.proposta.segurado.situacaoPrevidenciaria,
          observacao: this.parametro.observacao,
          statusFinanceiro: this.parametro.statusFinanceiro,
          taxaIndiceInflacionario: formatarValorPorcentagem(
            Number(this.parametro.proposta.segurado.tomador.instituto.taxaIndiceInflacionario),
          ),
          navegadorUtilizado: this.parametro.proposta.navegadorUtilizado,
          dataAprovado: formatarDataHora(this.parametro.proposta.dataAprovado),
          dataPrimeiraParcela: formatarData(this.parametro.parcela.sort((a, b) => a.parcela - b.parcela).shift().vencimento),
        }
      },
      abreModalAlterarMatriculaVinculada() {
        this.parametroAlteracaoMatricula = {
          tomadorId: this.parametro.proposta.segurado.tomador.id,
          seguradoId: this.parametro.seguradoId,
          valorDaParcela: formatarParaNumber(this.parametro.valorParcela),
          contratoId: this.parametro.id,
        }
        this.$refs['modal-altera-matricula-vinculada'].show()
      },
      fecharModalAlterarMatriculaVinculada() {
        this.$refs['modal-altera-matricula-vinculada'].hide()
        this.$emit('reloadComponent', this.parametro.id)
      },
    },
  }
</script>
<style>
  .label-branco label {
    color: white;
  }
</style>
