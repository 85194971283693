<template>
  <b-col md="12">
    <div class="text-center py-2">
      <h2 small v-if="loading" class="mb-2">Carregando...</h2>
      <b-spinner medium v-if="loading" />
    </div>
  </b-col>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarDataCurto, formatarParaNumber, formatarValor, PascalCaseComEspaco } from '@/libs/utils'
  import { formatarDataExtensa } from '@/utils/Functions/Formatacao/Formatacao'
  import { encryptNumber } from '@/utils/encryption'
  import { agendaIcon, blankIcon, keyIcon } from '@/utils/image-module/check-icon'
  import { generateImage } from '@/utils/image-module/image-module.helper.js'
  import JSZipUtils from 'jszip-utils'
  import * as Math from 'mathjs'
  import moment from 'moment'
  import PizZip from 'pizzip'
  import Ripple from 'vue-ripple-directive'
  import saveAs from 'file-saver'
  export default {
    components: {
      formatarData,
      formatarValor,
      formatarDataCurto,
      formatarDataExtensa,
      Math,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
      assinaturas: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        bancarioitems: [],
        primeiraParcela: [],
        ultimaParcela: [],
        loading: false,
        valorContrato: null,
        sexoOpcoes: {
          F: 'Feminino',
          M: 'Masculino',
          Feminino: 'Feminino',
          Masculino: 'Masculino',
          feminino: 'Feminino',
          Masculino: 'Masculino',
        },
      }
    },
    async mounted() {
      if (this.parametro) {
        this.loading = true
        await this.carregarContrato(this.parametro.contratoId)
      }
    },
    methods: {
      carregarContrato(item) {
        useJwt
          .get(`financeiro/${item}`)
          .then(async (response) => {
            let valorTotalContrato = parseFloat('0.00')
            this.contrato = response.data
            if (this.contrato.parcela.length == 0) {
              this.$emit(
                'contratoEvent',
                'error',
                'Não foi possível gerar o contrato, pois não há parcelas cadastradas para este contrato.',
              )
            }
            this.contrato.parcela.forEach((item) => {
              if (item.parcela == this.contrato.numeroParcelas) {
                this.ultimaParcela = item.vencimento
              } else if (item.parcela == '1') {
                this.primeiraParcela = item.vencimento
              }

              valorTotalContrato = valorTotalContrato + formatarParaNumber(item.valor)
            })
            this.valorContrato = valorTotalContrato.toFixed(2)

            const bancoId = this.contrato.proposta.segurado.tomador.bancoId
            await this.carregarOrgao()
            if (bancoId) this.carregarBanco(bancoId)
            else this.$message.error('O tomador não possui dados bancários cadastrados corretamente! Favor verificar')
          })
          .catch((error) => {
            this.loading = false
            console.error(error)
          })
      },
      carregarBanco(item) {
        useJwt
          .get(`utils/GetBancos/${item}`)
          .then((response) => {
            this.bancarioitems = response.data
            this.renderDoc(this.parametro)
          })
          .catch((error) => {
            this.loading = false
            console.error(error)
          })
      },
      formatDate(dateString) {
        const date = new Date(dateString)
        return new Intl.DateTimeFormat('pt-BR', {
          dateStyle: 'short',
          timeStyle: 'long',
          timeZone: 'America/Sao_Paulo',
        }).format(date)
      },
      carregarOrgao() {
        useJwt
          .get(`cadastro/orgao/${this.contrato.proposta.segurado.orgaoId}`)
          .then((response) => {
            this.orgaoSegurado = response.data.nome
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async renderDoc(parametro) {
        this.loading = true
        let tomador = this.contrato.proposta.segurado.tomador

        if (tomador.dadoBancarioFormaDePagamento == 'creditoEmContaPoupanca') {
          tomador.dadoBancarioFormaDePagamento = 'Conta Poupança'
        } else if (tomador.dadoBancarioFormaDePagamento == 'creditoEmContaCorrente') {
          tomador.dadoBancarioFormaDePagamento = 'Conta Corrente'
        } else {
          tomador.dadoBancarioFormaDePagamento = null
        }

        let valorDoContrato = Math.bignumber(formatarParaNumber(this.contrato.proposta.valor))
        let valorDoContratoComIOF = Math.bignumber(formatarParaNumber(this.contrato.proposta.valorComIOF))

        const valorTotalEmprestimo = Number(Math.subtract(valorDoContrato, valorDoContratoComIOF))
        this.valorTotalEmprestimo = Number(
          Math.add(valorTotalEmprestimo, formatarParaNumber(this.contrato.proposta.valorComIOF)),
        )

        var self = this
        let data = {
          nomeTomador: ` ${PascalCaseComEspaco(tomador.nomeCompleto ?? '')}`,
          CPFTomador: `${tomador.cpf == null ? '' : tomador.cpf}`,
          SEXOTomador: `${tomador.sexo == null ? '' : this.sexoOpcoes[tomador.sexo]}`,
          NATtomador: `${PascalCaseComEspaco(tomador.naturalidade ?? '')}`, //Naturalidade do tomador
          DatanascTomador: `${
            tomador.dataNascimento == undefined ? '' : moment(tomador.dataNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')
          }`, //Data de Nascimento do Tomador
          RGTomador: `${tomador.rgNumero}`,
          OrgaoTomador: `${tomador.rgOrgaoEmissor}`,
          UFrgemitida: `${tomador.rgUF == undefined ? '' : tomador.rgUF}`, //UF do rg
          EstadocivilTomador: `${PascalCaseComEspaco(tomador.estadoCivil ?? '')}`,
          NomePaiTomador: `${PascalCaseComEspaco(tomador.nomePai ?? '')}`,
          NomemaeTomador: `${PascalCaseComEspaco(tomador.nomeMae ?? '')}`,
          LogradouroTomador: `${PascalCaseComEspaco(tomador.logradouroEndereco ?? '')}`,
          Ntomador: `${tomador.numeroEndereco}`, //Número do endereço do Tomador
          CompTomador: `${tomador.complementoEndereco == null ? '' : tomador.complementoEndereco}`, //Complemento do endereço do Tomador
          BairroTomador: `${PascalCaseComEspaco(tomador.bairroEndereco ?? '')}`,
          CidadeTomador: `${PascalCaseComEspaco(tomador.cidadeEndereco ?? '')}`,
          UFtomador: `${tomador.ufEndereco}`,
          CEPTomador: `${tomador.cepEndereco}`,
          TelefoneTomador: `${tomador.telefone}`,
          emailTomador: `${tomador.email?.toLowerCase() ?? ''}`,
          BancoTomador: PascalCaseComEspaco(
            `${this.bancarioitems.LongName || this.bancarioitems.nome}` + ' - ' + `${this.bancarioitems?.codigo.split('-')[0]}`,
          ),
          AgenciaTomador: `${tomador.dadoBancarioAgencia}-${tomador.dadoBancarioAgenciaDV}`,
          ContaCorrenteTomador: `${tomador.dadoBancarioConta}-${tomador.dadoBancarioContaDV}`,
          tipodeconta: `${tomador.dadoBancarioFormaDePagamento == null ? '' : tomador.dadoBancarioFormaDePagamento}`,
          DataRequerimento: `${formatarData(this.contrato.createdAt)}`,
          NumContrato: `${this.contrato.codigo}`,
          TaxadeJurosMensal: `${this.contrato.taxas}`,
          SomaTaxasIPCA: `${((Math.pow(1 + this.contrato.taxas / 100, 12) - 1) * 100).toFixed(4)}`,
          valorBaseCalculo: `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
            this.contrato.proposta.valorBaseCalculo,
          )}`,
          valorTotalEmprestado: `${formatarValor(valorDoContratoComIOF)}`,
          SomaParcelasContrato: `${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
            this.contrato.proposta.valorTotalComJuros,
          )}`, //Para declarar o valor total do contrato, deverá ser realizado a soma de todas as parcelas do contrato do tomador
          NumeroParcelas: `${this.contrato.numeroParcelas}`, //quantidade de parcelas
          ValorPrimeiraParcela: `${formatarValor(this.contrato.valorParcela)}`,
          VencimentoPrimeiraParcelas: `${formatarData(this.primeiraParcela)}`,
          VencimentoUltimaParcelas: `${formatarData(this.ultimaParcela)}`,
          NomeInstituto: `${this.userData.Instituto.razaoSocial}`,
          CNPJInstituto: `${this.userData.Instituto.cnpj}`,
          LogradouroInstituto: `${this.userData.Instituto.logradouro}`,
          cidadeinstituto: `${this.userData.Instituto.cidade}`,
          ValorIOF: `${formatarValor(this.contrato.proposta.valorIOF)}`,
          Valordequitacao: `${formatarValor(this.contrato?.proposta.valorDaDivida)}`,
          Saldo: `${formatarValor(this.contrato.proposta?.valorRemanescente)}`,
          BancoQuitacao: this.contrato.proposta?.nomeBanco,
          TipoChavePix: `${tomador.tipoChavePix ? tomador.tipoChavePix : 'Não possui'}`,
          ChavePix: `${tomador.chavePix ? tomador.chavePix : 'Não possui'}`,
          DataInicioContrato: `${formatarDataExtensa(this.contrato.createdAt)}`,
          cargo: this.contrato.proposta.segurado.cargo ? this.contrato.proposta.segurado.cargo : 'Sem cargo',
          parcelasQuitadas: this.contrato.proposta.parcelasQuitadas ? this.contrato.proposta.parcelasQuitadas : '0',
          LotacaoTomador: this.orgaoSegurado ? `${this.orgaoSegurado}` : 'Não possui',
          Matricula: `${this.contrato.proposta.segurado.matricula}`,
        }

        const component = this

        function errorHandler(error) {
          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation
              })
              .join('\n')
            console.error(error)
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error
        }

        const encryptedNumber = encryptNumber(parametro.contratoId)
        let fields = {
          cabecalho: data.cabecalho,
          nomeTomador: PascalCaseComEspaco(data.nomeTomador ?? ''),
          CPFTomador: data.CPFTomador,
          SEXOTomador: data.SEXOTomador,
          NATtomador: data.NATtomador, //Naturalidade do tomador
          DatanascTomador: data.DatanascTomador, //Data de Nascimento do Tomador
          RGTomador: data.RGTomador,
          OrgaoTomador: data.OrgaoTomador,
          UFrgemitida: data.UFrgemitida, //UF do rg
          EstadocivilTomador: data.EstadocivilTomador,
          NomePaiTomador: data.NomePaiTomador,
          NomemaeTomador: data.NomemaeTomador,
          LogradouroTomador: data.LogradouroTomador,
          Ntomador: data.Ntomador, //Número do endereço do Tomador
          CompTomador: data.CompTomador, //Complemento do endereço do Tomador
          BairroTomador: data.BairroTomador,
          CidadeTomador: data.CidadeTomador,
          UFtomador: data.UFtomador,
          CEPTomador: data.CEPTomador,
          TelefoneTomador: data.TelefoneTomador,
          emailTomador: data.emailTomador,
          BancoTomador: data.BancoTomador,
          AgenciaTomador: data.AgenciaTomador,
          ContaCorrenteTomador: data.ContaCorrenteTomador,
          tipodeconta: data.tipodeconta,
          DataRequerimento: data.DataRequerimento,
          NumContrato: data.NumContrato,
          TaxadeJurosMensal: data.TaxadeJurosMensal,
          SomaTaxasIPCA: data.SomaTaxasIPCA,
          ValorTotalEmprestado: data.valorTotalEmprestado,
          valorBaseCalculo: data.valorBaseCalculo,
          SomaParcelasContrato: data.SomaParcelasContrato, //Para declarar o valor total do contrato, deverá ser realizado a soma de todas as parcelas do contrato do tomador
          NumeroParcelas: data.NumeroParcelas, //quantidade de parcelas
          ValorPrimeiraParcela: data.ValorPrimeiraParcela,
          VencimentoPrimeiraParcelas: data.VencimentoPrimeiraParcelas,
          VencimentoUltimaParcelas: data.VencimentoUltimaParcelas,
          NomeInstituto: data.NomeInstituto,
          CNPJInstituto: data.CNPJInstituto,
          LogradouroInstituto: data.LogradouroInstituto,
          cidadeinstituto: data.cidadeinstituto,
          codigoVerificacao: encryptedNumber,
          linkDeVerificacao: `${window.location.origin}/financeiro/verifica-assinatura/${encryptedNumber}`,
          logoAgenda: agendaIcon,
          ValorIOF: data.ValorIOF,
          Valordequitacao: data.Valordequitacao,
          Saldo: data.Saldo,
          BancoQuitacao: data.BancoQuitacao,
          TipoChavePix: data.TipoChavePix,
          ChavePix: data.ChavePix,
          DataInicioContrato: data.DataInicioContrato,
          cargo: data.cargo,
          parcelasQuitadas: data.parcelasQuitadas,
          LotacaoTomador: data.LotacaoTomador,
          Matricula: data.Matricula,
        }

        let out = undefined
        try {
          out = await generateImage(
            parametro.s3Key,
            fields,
            `${window.location.origin}/financeiro/verifica-assinatura/${encryptedNumber}`,
          )
        } catch (error) {
          // Catch rendering errors (errors relating to the rendering of the template : angularParser throws an error)
          errorHandler(error)
        }

        const formData = new FormData()
        formData.append('userId', parametro.userId)
        formData.append('financeiroId', parametro.contratoId)
        formData.append('file', out, parametro.nomeDocumento)
        formData.append('documentoId', parametro.idDocumento)
        useJwt
          .postDocumento('financeiro/documentacaoProcessoPdf', () => {}, formData)
          .then(async (response) => {
            const fileData = response.data
            const s3Key = fileData.s3Key
            const param = { s3Key }

            useJwt
              .post(`financeiro/getDownloadDocumentacaoProcesso`, param)
              .then((response) => {
                const data = response.data
                const buffer = new Uint8Array(data.Body.data) // Extrai o buffer dos dados
                const blob = new Blob([buffer], { type: data.ContentType })
                saveAs(blob, 'contrato.pdf')
                const url = window.URL.createObjectURL(blob)
                window.open(url, '_blank')
                setTimeout(() => window.URL.revokeObjectURL(url), 100)
              })
              .catch((error) => {
                console.error(error)
                self.$message.error('Erro ao realizar o download!')
              })
              .finally(() => {
                self.loading = false
                self.$emit('contratoEvent', out)
              })
          })
          .catch((error) => {
            console.error('Erro ao enviar o documento:', error)
          })
          .finally(() => {
            self.loading = false
            self.$emit('contratoEvent', out)
          })
      },
    },
  }
</script>

<style></style>
