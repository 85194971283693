<template>
  <div>
    <b-card border-variant="primary" v-show="this.totalRows === 0">
      <h3 class="text-black mb-2">Não há parcelas disponíveis para este contrato</h3>
      <b-card-text class="text-black mb-5"> Nenhuma parcela foi encontrada no cadastrado deste contrato. </b-card-text>
    </b-card>

    <b-col md="12">
      <template>
        <b-dropdown
          class="d-flex"
          style="align-items: flex-end; flex-direction: column-reverse"
          variant="link"
          no-caret
          toggle-class="p-0"
          right
          :popper-opts="{ positionAbsolute: true }"
        >
          <template #button-content>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" style="margin-bottom: 15px; width: 160px">
              Exportar
            </b-button>
          </template>

          <b-dropdown-item href="#" @click="exportToExcel(tabela)">
            <feather-icon icon="CheckCircleIcon" size="16" />
            <span class="align-middle ml-50" title="Exportar tabela para excel">Excel</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-col>
    <!-- Tabela -->
    <b-table
      id="tabelaInformacoesAvancadas"
      v-show="this.totalRows"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :current-page="pesquisar.currentPage"
      :items="tabela"
      :fields="!apresentaPosFixado ? fields : fieldsPosFixado"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      <template #cell(parcela)="row">
        {{ row.item.parcela }}
      </template>
      <template #cell(valor)="row">
        {{ row.item.valor ? formatarValor(row.item.valor) : formatarValor(0) }}
      </template>
      <template #cell(amortizacao)="row">
        {{ row.item.amortizacao ? formatarValor(row.item.amortizacao) : formatarValor(0) }}
      </template>
      <template #cell(juros)="row">
        {{ row.item.juros ? formatarValor(row.item.juros) : formatarValor(0) }}
      </template>
      <template #cell(jurosAtuarial)="row">
        {{ row.item.jurosAtuarial ? formatarValor(row.item.jurosAtuarial) : formatarValor(0) }}
      </template>
      <template #cell(indiceAtualizacao)="row">
        {{ row.item.indiceAtualizacao ? formatarValor(row.item.indiceAtualizacao) : formatarValor(0) }}
      </template>
      <template #cell(fundoGarantidor)="row">
        {{ row.item.fundoGarantidor ? formatarValor(row.item.fundoGarantidor) : formatarValor(0) }}
      </template>
      <template #cell(administracao)="row">
        {{ row.item.administracao ? formatarValor(row.item.administracao) : formatarValor(0) }}
      </template>
      <template #cell(oscilacaoRiscos)="row">
        {{ row.item.oscilacaoRiscos ? formatarValor(row.item.oscilacaoRiscos) : formatarValor(0) }}
      </template>
      <template #cell(taxaDeOportunidade)="row">
        {{ row.item.taxaDeOportunidade ? formatarValor(row.item.taxaDeOportunidade) : formatarValor(0) }}
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import vSelect from 'vue-select'
  import { VueAutosuggest } from 'vue-autosuggest'
  import { formatarValor, formatarData } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import { asDefault } from '../exports/contratoContratoInformacoesAvancadasExport'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      vSelect,
      VueAutosuggest,
      AutenticaSenha,
      formatarData,
      asDefault,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        isBusy: true,
        formatarValor,
        formatarData,
        number: {},
        suggestions: [],
        timeout: null,
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        required,

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 10, currentPage: 1 },
        striped: true,

        apresentaPosFixado: false, //Verifica se o contrato é pos fixado

        //Campos tabela
        fields: [
          { key: 'parcela', label: 'Nº da Parcela', sortable: true },
          { key: 'valor', label: 'Valor Total', sortable: true, variant: 'success' },
          { key: 'amortizacao', label: 'Amortização', sortable: true, variant: 'warning' },
          { key: 'juros', label: 'Custos', sortable: true, variant: 'warning' },
          { key: 'jurosAtuarial', label: 'Juros Atuarial', sortable: true, variant: 'primary' },
          { key: 'taxaDeOportunidade', label: 'Performance', sortable: true, variant: 'primary' },
          { key: 'indiceAtualizacao', label: 'Índice inflacionário', sortable: true, variant: 'primary' },
          { key: 'fundoGarantidor', label: 'Fundo Garantidor/Seguro Prestamista', sortable: true, variant: 'primary' },
          { key: 'administracao', label: 'Taxa Administração', sortable: true, variant: 'primary' },
          { key: 'oscilacaoRiscos', label: 'Taxa Oscilação de Riscos', sortable: true, variant: 'primary' },
        ],
        fieldsPosFixado: [
          {
            key: 'parcela',
            label: 'Nº da Parcela',
            sortable: true,
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valor',
            label: 'Valor Total',
            sortable: true,
            variant: 'success',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'amortizacao',
            label: 'Amortização',
            sortable: true,
            variant: 'warning',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'juros',
            label: 'Custos',
            sortable: true,
            variant: 'warning',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valorTaxaAdministrativaPosFixado',
            label: 'Taxa Administratação',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valorJurosAtuariaisPosFixado',
            label: 'Juros Atuariais',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valorFundoGarantidorPosFixado',
            label: 'Fundo Garantidor/Seguro Prestamista',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'valorIndiceInflacionarioPosFixado',
            label: 'Índice Inflacionário',
            sortable: true,
            variant: 'primary',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
        ],
        tabela: [],
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.parametro.tipoDeCalculoUtilizado === 'posFixado') {
        this.apresentaPosFixado = true
      }
      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar(`financeiro/GetbuscaParcelas/${this.parametro.id}`)
          .then((response) => {
            this.isBusy = !this.isBusy
            this.tabela = response.data
            this.totalRows = response.data.length
          })
          .catch((error) => {
            console.error(error)
          })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
        }
      },
      exportToExcel(data) {
        try {
          this.$message.warning('Aguarde, estamos gerando o relatório')
          asDefault().export(data)
          this.$message.success('Relatório gerado com sucesso!')
        } catch (error) {
          this.$message.error('Ocorreu um erro ao gerar o relatório', 'error')
          console.error(error)
        }
      },
    },
  }
</script>

<style lang="scss">
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-success {
    background-color: #16a085 !important;
    color: white;
  }
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-warning {
    background-color: #f39c12 !important;
    color: white;
  }
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-primary {
    background-color: #2980b9 !important;
    color: white;
  }
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-danger {
    background-color: #b92929 !important;
    color: white;
  }
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-success,
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-warning,
  table#tabelaInformacoesAvancadas > tbody > tr > td.table-primary {
    color: white;
  }
</style>
