import * as XLSX from 'xlsx'
import { formatarParaNumber } from '@/libs/utils'

const createSheet = (tableData) => {
  const ws = XLSX.utils.json_to_sheet(tableData)

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        'Nº DA PARCELA',
        'VALOR TOTAL',
        'AMORTIZAÇÃO',
        'CUSTOS',
        'JUROS ATUARIAL',
        'PERFORMANCE',
        'ÍNDICE INFLACIONÁRIO',
        'FUNDO GARANTIDOR/SEGURO PRESTAMISTA',
        'TAXA ADMINISTRAÇÃO',
        'TAXA OSCILAÇÃO DE RISCOS',
      ],
    ],
    { origin: 'A1' },
  )

  const columnWidths = [
    { wch: 10 },
    { wch: 12 },
    { wch: 15 },
    { wch: 10 },
    { wch: 15 },
    { wch: 25 },
    { wch: 20 },
    { wch: 15 },
    { wch: 20 },
    { wch: 25 },
  ]
  ws['!cols'] = columnWidths

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Relatório Geral')

  XLSX.writeFile(wb, 'Informações avançadas.xlsx')
}

export const asDefault = () => {
  return {
    convertToExcelFormat: (item) => {
      return {
        'Nº DA PARCELA': item.parcela ?? '',
        'VALOR TOTAL': item.valor ? formatarParaNumber(item.valor) : formatarParaNumber(0),
        AMORTIZAÇÃO: item.amortizacao ? formatarParaNumber(item.amortizacao) : formatarParaNumber(0),
        CUSTOS: item.juros ? formatarParaNumber(item.juros) : formatarParaNumber(0),
        'JUROS ATUARIAL': item.jurosAtuarial ? formatarParaNumber(item.jurosAtuarial) : formatarParaNumber(0),
        PERFORMANCE: item.taxaDeOportunidade ? formatarParaNumber(item.taxaDeOportunidade) : formatarParaNumber(0),
        'ÍNDICE INFLACIONÁRIO': item.indiceAtualizacao ? formatarParaNumber(item.indiceAtualizacao) : formatarParaNumber(0),
        'FUNDO GARANTIDOR/SEGURO PRESTAMISTA': item.fundoGarantidor
          ? formatarParaNumber(item.fundoGarantidor)
          : formatarParaNumber(0),
        'TAXA ADMINISTRAÇÃO': item.administracao ? formatarParaNumber(item.administracao) : formatarParaNumber(0),
        'TAXA OSCILAÇÃO DE RISCOS': item.oscilacaoRiscos ? formatarParaNumber(item.oscilacaoRiscos) : formatarParaNumber(0),
      }
    },
    export: (items) => {
      const situationItems = items.map((item) => asDefault().convertToExcelFormat(item))
      createSheet(situationItems)
    },
  }
}
